import React from "react";
import left from "../../assets/images/shape-say-shape-left.png";
import right from "../../assets/images/shape-say-shape-right.png";

export const Collaboration = () => {
  return (
    <div id="contact-us" className="say-something-aera pt-90 pb-90 fix">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="offset-xl-1 offset-lg-1 col-xl-5 col-lg-5">
            <div className="say-something-cap">
              <h2>Say hello to the Wisdom.</h2>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3">
            <div className="say-btn">
              <a
                href="mailto:contact@wiseme.io?subject=Contact Us: Wiseme"
                className="btn radius-btn"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="say-shape">
        <img
          src={left}
          alt=""
          className="say-shape1 rotateme d-none d-xl-block"
        />
        <img src={right} alt="" className="say-shape2 d-none d-lg-block" />
      </div>
    </div>
  );
};
