import React from "react";

const PricingPlans = () => {
  return (
    <div>
      <section
        id="app-pricing"
        className="best-pricing pricing-padding"
        data-background="assets/img/gallery/best_pricingbg.jpg"
      >
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="section-tittle section-tittle2 text-center">
                <h2>Choose Your Very Best Plan.</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="pricing-card-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="single-card text-center mb-30">
                <div className="card-top">
                  <span>7 Days</span>
                  <h4>Free</h4>
                </div>
                <div className="card-bottom">
                  <ul>
                    <li>For New Users</li>
                    <li>Access to admin</li>
                    <li>Create Team</li>
                    <li>Manage Teams</li>
                    <li>Best for One off events</li>
                    <li>No automatic charges after trial</li>
                  </ul>
                  <a href="#contact-us" className="btn card-btn1">
                    Free Trial
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="single-card text-center mb-30">
                <div className="card-top">
                  <span>7 Days</span>
                  <h4>$10</h4>
                </div>
                <div className="card-bottom">
                  <ul>
                    <li>Starts after free trial</li>
                    <li>Access to admin</li>
                    <li>Create Team</li>
                    <li>Manage Teams</li>
                    <li>Best for One off events</li>
                    <li>Charged once</li>
                  </ul>
                  <a href="#contact-us" className="btn card-btn1">
                    Get Started
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="single-card text-center mb-30">
                <div className="card-top">
                  <span>Monthly</span>
                  <h4>$30</h4>
                </div>
                <div className="card-bottom">
                  <ul>
                    <li>Starts after free trial</li>
                    <li>Access to admin</li>
                    <li>Create Team</li>
                    <li>Manage Teams</li>
                    <li>Best for Corporates</li>
                    <li>Recurring payment</li>
                  </ul>
                  <a href="#contact-us" className="btn card-btn1">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlans;
