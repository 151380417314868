import React from "react";
import logo from "../../assets/img/full_logo.png";

const HeaderComponent = () => {
  return (
    <header>
      <div className="header-area header-transparrent">
        <div className="main-header header-sticky">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2 col-md-2">
                <img src={logo} alt="" style={{ width: "200px" }} />
              </div>
              <div className="col-xl-10 col-lg-10 col-md-10">
                <div className="main-menu f-right d-none d-lg-block">
                  <nav>
                    <ul id="navigation">
                      <li className="active">
                        <a href="/"> Home</a>
                      </li>
                      <li>
                        <a href="#app-features">Feature</a>
                      </li>
                      <li>
                        <a href="#app-pricing">Pricing</a>
                      </li>
                      <li>
                        <a href="#">Download</a>
                        <ul className="submenu">
                          <li>
                            <a href="market://details?id=com.itsunicorn.wiseme&url=https://wiseme.io/app">
                              Android
                            </a>
                          </li>
                          <li>
                            <a href="https://apps.apple.com/in/app/wiseme/id1669168716">
                              iOS
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#contact-us">Contact</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="col-12">
                <div className="mobile_menu d-block d-lg-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
