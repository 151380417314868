import React from "react";
import screenshot1 from "../../assets/img/main screen.png";
import screenshot2 from "../../assets/img/leaderboard.png";
import screenshot3 from "../../assets/img/quiz.png";
import screenshot4 from "../../assets/img/topic.png";
import screenshot5 from "../../assets/img/learning.png";
import OwlCarousel from "react-owl-carousel";
const AppScreenShots = () => {
  return (
    <div className="applic-apps section-padding2">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-8">
            <div className="single-cases-info mb-30">
              <h3>Glimpse Inside</h3>
              <p>
                Experience the heart of WiseMe's innovation, where curiosity
                meets discovery. Embrace a learning journey that's as dynamic as
                you are, unlocking new horizons and forging connections that
                enrich your personal and professional growth.
              </p>
            </div>
          </div>

          <div className="col-xl-8 col-lg-8 col-md-col-md-7">
            <OwlCarousel loop margin={10}>
              <div className="single-cases-img">
                <img src={screenshot1} alt="" />
              </div>
              <div className="single-cases-img">
                <img src={screenshot2} alt="" />
              </div>
              <div className="single-cases-img">
                <img src={screenshot3} alt="" />
              </div>
              <div className="single-cases-img">
                <img src={screenshot4} alt="" />
              </div>
              <div className="single-cases-img">
                <img src={screenshot5} alt="" />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppScreenShots;
