import React, { useEffect } from "react";
import Logo from "../assets/icons/logo.png";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="header">
        <img src={Logo} />
      </div>
      <div className="section">
        <h1>Privacy Policy</h1>
        <p>Last Updated: May 22, 2023</p>
        <p>
          WiseMe Ltd. ("WiseMe," "we," "us," or "our") is committed to
          protecting your privacy. This Privacy Policy describes how we collect,
          use, and disclose personal information when you use the WiseMe App
          ("the App"). By using the App, you agree to the collection and use of
          information in accordance with this Privacy Policy.
        </p>

        <h2>Information We Collect</h2>
        <h3>1.1. Information You Provide</h3>

        <p>
          When you create an account, we collect the following personal
          information: your name, email address, and password. You may also
          provide additional information, such as your age, gender, location,
          and educational interests, which helps us personalize your experience
          on the App.
        </p>
        <h3>1.2. User Content</h3>
        <p>
          We collect the quizzes, courses, and other educational materials you
          create, upload, or share on the App ("User Content").
        </p>

        <h3>1.3. Usage Data</h3>
        <p>
          We automatically collect information about your use of the App, such
          as the pages you visit, the time and date of your visit, the device
          and operating system you use, and your IP address.
        </p>
        <h3>1.4. Cookies and Similar Technologies</h3>
        <p>
          We use cookies and similar technologies to enhance your experience,
          provide our services, and analyze your usage of the App.
        </p>
        <h3>How We Use Your Information</h3>
        <p>
          We use the information we collect for various purposes, including:
          <ul>
            <li>To provide and maintain the App;</li>
            <li>
              To personalize your experience and provide you with content
              tailored to your interests;
            </li>
            <li>To monitor and analyze usage of the App;</li>
            <li>
              To communicate with you about updates, promotions, and events;
            </li>
            <li>To detect, prevent, and address technical issues;</li>
            <li>
              To comply with legal obligations and enforce our Terms &
              Conditions.
            </li>
          </ul>
        </p>
        <h2>Sharing Your Information</h2>
        <h3>3.1. Service Providers</h3>
        <p>
          We may employ third-party companies and individuals to provide
          services on our behalf, such as hosting, analytics, and customer
          support. These third parties have access to your personal information
          only to perform these tasks on our behalf and are obligated not to
          disclose or use it for any other purpose.
        </p>
        <h3>3.2. Compliance with Laws and Law Enforcement</h3>
        <p>
          We may disclose your personal information if required to do so by law
          or in the good-faith belief that such action is necessary to comply
          with legal obligations, protect and defend our rights or property,
          ensure the safety of our users, or investigate suspected fraud or
          other illegal activities.
        </p>

        <h3>3.3. Business Transfers</h3>
        <p>
          In the event of a merger, acquisition, or any form of sale or transfer
          of some or all of our assets, your personal information may be
          transferred to the acquiring entity.
        </p>
        <h3>International Data Transfers</h3>
        <p>
          Your personal information may be transferred to and processed in
          countries other than the one in which you reside, including countries
          outside of New Zealand. These countries may have different data
          protection laws, but we ensure that your personal information remains
          protected in accordance with this Privacy Policy and applicable laws.
        </p>
        <h3>Data Retention</h3>
        <p>
          We retain your personal information for as long as necessary to
          provide the App and fulfill the purposes outlined in this Privacy
          Policy, unless a longer retention period is required or permitted by
          law.
        </p>
        <h3>Security</h3>
        <p>
          We take reasonable precautions to protect your personal information
          from unauthorized access, use, or disclosure. However, no method of
          transmission over the Internet or method of electronic storage is
          completely secure, and we cannot guarantee the absolute security of
          your personal information.
        </p>
        <h3>Your Rights</h3>
        <p>
          You have the right to access, update, or delete your personal
          information at any time by logging into your account and adjusting
          your settings. You may also request a copy of your personal
          information or ask us to rectify or erase any inaccuracies in your
          information.
        </p>
        <h3>Children's Privacy in Compliance with Families Policy</h3>
        <p>
          We are committed to providing a safe and secure environment for all
          our users, especially children. If your child is under the age of 13,
          we offer a child-friendly experience that complies with the Google
          Play Families Policy Requirements.
          <br />
          We do not knowingly collect, use, or disclose personal information
          from children under the age of 13 without parental consent, except as
          permitted by applicable laws. We only collect personal information
          that is necessary for the use of the App, such as username, and do not
          ask children for more information than necessary.
          <br />
          Parents or guardians have the right to review, update, or delete their
          child's personal information, and can refuse to permit further
          collection or use of their child's information. Parents or guardians
          can exercise these rights by contacting us at contact@wiseme.io
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. We will notify you of any material changes by posting the
          updated Privacy Policy on the App or by sending you an email
          notification. It is your responsibility to review the Privacy Policy
          periodically to stay informed of any changes. Your continued use of
          the App constitutes your acceptance of any modifications to the
          Privacy Policy.
        </p>
        <h3>Third-Party Websites and Services</h3>
        <p>
          The App may contain links to third-party websites or services that are
          not owned or controlled by us. We are not responsible for the privacy
          practices of such third-party websites or services. We encourage you
          to review the privacy policies of any third-party websites or services
          you visit.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy or the
          App, please contact us at contact@wiseme.io
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
