import React from "react";
import Logo from "../assets/icons/logo.png";
import { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="header">
        <img src={Logo} />
      </div>
      <div className="section">
        <h1>Terms and Conditions</h1>
        <p>
          Welcome to WiseMe, a New Zealand-based learning platform designed to
          provide interactive quizzes, courses, and study tools for users of all
          ages. Please read these Terms & Conditions (T&C) carefully, as they
          govern your access to and use of the WiseMe App ("the App"). By using
          the App, you agree to be bound by these T&C.
        </p>

        <h3>Acceptance of Terms & Conditions</h3>

        <p>
          By creating an account, accessing, or using the App, you acknowledge
          that you have read, understood, and agreed to be legally bound by
          these T&C and our Privacy Policy, which is incorporated herein by
          reference.
        </p>
        <h3>Changes to Terms & Conditions</h3>
        <p>
          We reserve the right to modify or update these T&C at any time, and we
          will notify you of any material changes. It is your responsibility to
          review the T&C periodically to stay informed of any changes. Your
          continued use of the App constitutes your acceptance of any
          modifications to the T&C.
        </p>

        <h3>Eligibility</h3>
        <p>
          If you are under the age of 18, you represent that you have the
          consent of a parent or legal guardian to use the App. If you do not
          meet these requirements, you may not use the App.
        </p>
        <h3>Account Registration</h3>
        <p>
          To access certain features of the App, you will need to create an
          account. You agree to provide accurate and complete information during
          the registration process and to keep your account information
          up-to-date. You are responsible for maintaining the confidentiality of
          your account information, and you agree to accept responsibility for
          all activities that occur under your account.
        </p>
        <h3>License to Use the App</h3>
        <p>
          Subject to your compliance with these T&C, we grant you a limited,
          non-exclusive, non-transferable, non-sublicensable, revocable license
          to access and use the App for your personal, non-commercial use.
        </p>
        <h2>Content</h2>
        <h3>6.1. User Content</h3>
        <p>
          The App allows you to create, upload, and share quizzes, courses, and
          other educational materials ("User Content"). You represent that you
          have all necessary rights to create and share your User Content. You
          retain ownership of your User Content, and you grant us a worldwide,
          non-exclusive, royalty-free, sublicensable, and transferable license
          to use, reproduce, distribute, display, and perform your User Content
          in connection with the App.
        </p>
        <h3>6.2. Prohibited Content</h3>
        <p>
          You agree not to create, upload, or share any User Content that is
          unlawful, harmful, threatening, abusive, harassing, defamatory,
          vulgar, obscene, invasive of another's privacy, hateful, or racially,
          ethnically, or otherwise objectionable.
        </p>

        <h3>Copyright Policy</h3>
        <p>
          We respect the intellectual property rights of others and expect our
          users to do the same. If you believe that any content on the App
          infringes upon your copyrights, please submit a notice to our
          designated Copyright Agent in accordance with our Copyright Policy.
        </p>
        <h3>Third-Party Content and Links</h3>
        <p>
          The App may contain links to third-party websites, content, or
          services that are not owned or controlled by us. We are not
          responsible for the content, privacy practices, or any other aspect of
          such third-party websites or services.
        </p>
        <h3>Disclaimer of Warranties and Limitation of Liability</h3>
        <p>
          The App is provided on an "as is" and "as available" basis, without
          any warranties of any kind, express or implied. We do not warrant that
          the App will meet your requirements, be uninterrupted, timely, secure,
          or error-free. In no event shall we be liable for any direct,
          indirect, incidental, special, consequential, or punitive damages,
          including, but not limited to, loss of profits, goodwill, data,
          orother intangible losses, arising out of or in connection with your
          use of the App, even if we have been advised of the possibility of
          such damages.
        </p>
        <h3>Indemnification</h3>
        <p>
          You agree to defend, indemnify, and hold harmless WiseMe, its
          affiliates, and their respective directors, officers, employees, and
          agents from and against any and all claims, liabilities, damages,
          losses, or expenses, including reasonable attorneys' fees and costs,
          arising out of or in any way connected with your access to or use of
          the App, your User Content, or your violation of these T&C.
        </p>
        <h3>Governing Law and Dispute Resolution</h3>
        <p>
          These T&C are governed by the laws of New Zealand, without regard to
          its conflict of law provisions. Any disputes arising out of or
          relating to these T&C or the App shall be resolved through arbitration
          under the New Zealand Arbitration Act 1996. The arbitration shall be
          conducted in English, in a location mutually agreed upon by the
          parties.
        </p>
        <h3>Severability</h3>
        <p>
          If any provision of these T&C is deemed unlawful, void, or for any
          reason unenforceable, then that provision shall be deemed severable
          from these T&C and shall not affect the validity and enforceability of
          any remaining provisions.
        </p>
        <h3>Waiver</h3>
        <p>
          No waiver of any provision of these T&C by us shall be deemed a
          further or continuing waiver of such provision or any other provision,
          and our failure to assert any right or provision under these T&C shall
          not constitute a waiver of such right or provision.
        </p>
        <h3>Assignment</h3>
        <p>
          You may not assign or transfer your rights or obligations under these
          T&C without our prior written consent. We may freely assign or
          transfer our rights and obligations under these T&C without
          restriction.
        </p>
        <h3>Entire Agreement</h3>
        <p>
          These T&C, together with our Privacy Policy, constitute the entire
          agreement between you and us concerning your use of the App and
          supersede all prior or contemporaneous communications, proposals, and
          agreements, whether electronic, oral, or written, between you and us
          with respect to the App.
        </p>
        <h3>Contact Information</h3>
        <p>
          If you have any questions or concerns about these T&C or the App,
          please contact us.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
