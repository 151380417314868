import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/css-bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./assets/css/css-flaticon.css";
import "./assets/css/css-slicknav.css";
import "./assets/css/css-animate.min.css";
import "./assets/css/css-magnific-popup.css";
import "./assets/css/css-fontawesome-all.min.css";
import "./assets/css/css-themify-icons.css";
import "./assets/css/css-slick.css";
import "./assets/css/css-nice-select.css";
import "./assets/css/css-style.css";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TermsAndConditions from "./pages/terms-and-conditions";
import PrivacyPolicy from "./pages/privacy-policy";
import ContactUs from "./pages/contact-us";
import Home from "./pages/home/home";
import AppLinks from "./pages/app-links";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "app/*",
    element: <AppLinks />,
  },
  {
    path: "terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "contact-us",
    element: <ContactUs />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

reportWebVitals();
