import React from "react";
import LandingLogo from "../../assets/img/landinglogo.png";

const LandingBody = () => {
  return (
    <div className="slider-area">
      <div className="slider-active">
        <div className="single-slider slider-height slider-padding sky-blue d-flex align-items-center">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-9">
                <div className="hero__caption">
                  <span data-animation="fadeInUp" data-delay=".4s">
                    Wiseme
                  </span>
                  <h1 data-animation="fadeInUp" data-delay=".6s">
                    Learn Compete & Triumph
                    <br />
                    with Wiseme
                  </h1>
                  <p data-animation="fadeInUp" data-delay=".8s">
                    With topics from personal finance to Health, Sports, and
                    Science, WiseMe fills the gaps left by conventional
                    education systems. A tool important for team bonding,
                    curative fun for social gatherings.
                  </p>

                  <div className="slider-btns">
                    <a
                      data-animation="fadeInLeft"
                      data-delay="1.0s"
                      // href="market://details?id=com.itsunicorn.wiseme&url=https://wiseme.io/app"
                      onClick={() => {
                        let os = navigator.userAgent;
                        if (os.search("Android") !== -1) {
                          window.open(
                            "market://details?id=com.itsunicorn.wiseme&url=https://wiseme.io/app"
                          );
                        } else {
                          window.open(
                            "https://apps.apple.com/in/app/wiseme/id1669168716"
                          );
                        }
                      }}
                      href="#"
                      className="btn radius-btn"
                      target="_self"
                    >
                      Download Android & IOS
                    </a>
                    &nbsp; &nbsp; &nbsp;
                    <a
                      data-animation="fadeInRight"
                      data-delay="1.0s"
                      className="popup-video video-btn ani-btn"
                      href="link://wiseme.io/app"
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="hero__img d-none d-lg-block f-right"
                  data-animation="fadeInRight"
                  data-delay="1s"
                >
                  <img src={LandingLogo} alt="" style={{ height: "76vh" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBody;
