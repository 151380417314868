import React from "react";

import BestFeatures from "../../assets/img/best_features.png";

const AppFeatures = () => {
  return (
    <section id="app-features" className="best-features-area section-padd4">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-xl-8 col-lg-10">
            <div className="row">
              <div className="col-lg-10 col-md-10">
                <div className="section-tittle">
                  <h2>Experience the Difference</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="single-features mb-70">
                  <div className="features-icon">
                    <span className="flaticon-support"></span>
                  </div>
                  <div className="features-caption">
                    <h3>Interactive Quizzes</h3>
                    <p>
                      Choose your own category, and each question is paired with
                      an informative fact, providing depth and clarity to your
                      learning journey.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="single-features mb-70">
                  <div className="features-icon">
                    <span className="flaticon-support"></span>
                  </div>
                  <div className="features-caption">
                    <h3>Daily Challenges</h3>
                    <p>
                      Boost your knowledge daily with fresh challenges, tailored
                      to ensure that learning is a continuous and refreshing
                      process.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="single-features mb-70">
                  <div className="features-icon">
                    <span className="flaticon-support"></span>
                  </div>
                  <div className="features-caption">
                    <h3>Live Compete Mode</h3>
                    <p>
                      Engage in spirited live quiz competitions, pitting your
                      knowledge with a vibrant community of learners and get a
                      chance to win prizes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="single-features mb-70">
                  <div className="features-icon">
                    <span className="flaticon-support"></span>
                  </div>
                  <div className="features-caption">
                    <h3>Team Quiz</h3>
                    <p>
                      With WiseMe’s subscription model, you can curate your very
                      own community, organize team quizzes, and improve team
                      bonding.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="features-shpae d-none d-lg-block">
        <img src={BestFeatures} alt="" />
      </div>
    </section>
  );
};

export default AppFeatures;
