import React from "react";

import app_btn1 from "../../assets/images/shape-app_btn1.png";
import app_btn2 from "../../assets/images/shape-app_btn2.png";
import available from "../../assets/images/shape-available-app.png";
import top from "../../assets/images/shape-app-shape-top.png";
import left from "../../assets/images/shape-app-shape-left.png";

const AppDownload = () => {
  return (
    <div className="available-app-area">
      <div className="container">
        <div className="row d-flex justify-content-between">
          <div className="col-xl-5 col-lg-6">
            <div className="app-caption">
              <div className="section-tittle section-tittle3">
                <h2>Our App Available For All Devices Download now</h2>
                {/* <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore fug.
                </p> */}
                <div className="app-btn">
                  <a
                    href="https://apps.apple.com/in/app/wiseme/id1669168716"
                    className="app-btn1"
                  >
                    <img src={app_btn1} alt="" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.itsunicorn.wiseme&hl=en&gl=US"
                    className="app-btn2"
                  >
                    <img src={app_btn2} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="app-img">
              <img src={available} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="app-shape">
        <img
          src={top}
          alt=""
          className="app-shape-top heartbeat d-none d-lg-block"
        />
        <img src={left} alt="" className="app-shape-left d-none d-xl-block" />
      </div>
    </div>
  );
};

export default AppDownload;
