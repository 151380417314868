import React from "react";
import logo from "../../assets/img/full_logo.png";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer>
      <div className="footer-main">
        <div className="footer-area footer-padding">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-3 col-md-4 col-sm-8">
                <div className="single-footer-caption mb-30">
                  <div className="footer-logo">
                    <a href="index.html">
                      <img src={logo} height={30} alt="" />
                    </a>
                  </div>
                  <div className="footer-tittle">
                    <div className="footer-pera">
                      <p className="info1">
                        Stop Mindless scrolling and get Wise with WiseMe
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-5">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Quick Links</h4>
                    <ul>
                      <li>
                        <a href="/">About</a>
                      </li>
                      <li>
                        <a href="#app-features">Features</a>
                      </li>
                      <li>
                        <a href="#app-pricing">Pricing</a>
                      </li>
                      <li>
                        {/* <a href="#">Download</a> */}
                        <ul className="submenu">
                          <li>
                            <a href="market://details?id=com.itsunicorn.wiseme&url=https://wiseme.io/app">
                              Android
                            </a>
                          </li>
                          <li>
                            <a href="https://apps.apple.com/in/app/wiseme/id1669168716">
                              iOS
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="mailto:contact@wiseme.io?subject=Contact Us: Wiseme">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-7">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Support</h4>
                    <ul>
                      <li>
                        <a href="mailto:contact@wiseme.io?subject=Bug Report: Wiseme">
                          Report a bug
                        </a>
                      </li>
                      <li>
                        <Link to={"privacy-policy"}>Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to={"terms-and-conditions"}>
                          Terms & Conditions
                        </Link>
                      </li>
                      {/* <li>
                        <a href="#">FAQs</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-8">
                {/* <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Newsletter</h4>
                    <div className="footer-pera footer-pera2">
                      <p>Heaven fruitful doesn't over lesser in days. Appear</p>
                    </div>

                    <div className="footer-form">
                      <div id="mc_embed_signup">
                        <form
                          target="_blank"
                          action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                          method="get"
                          className="subscribe_form relative mail_part"
                          // novalidate="true"
                        >
                          <input
                            type="email"
                            name="EMAIL"
                            id="newsletter-form-email"
                            placeholder=" Email Address "
                            className="placeholder hide-on-focus"
                            // onFocus="this.placeholder = ''"
                            // onBlur="this.placeholder = ' Email Address '"
                          />
                          <div className="form-icon">
                            <button
                              type="submit"
                              name="submit"
                              id="newsletter-submit"
                              className="email_icon newsletter-submit button-contactForm"
                            >
                              <img src="images/shape-form_icon.png" alt="" />
                            </button>
                          </div>
                          <div className="mt-10 info"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
