import "./style.css";
import Logo from "./assets/icons/logo.png";
import { Link } from "react-router-dom";

function App() {
  return (
    <div className="container max-height">
      <div className="links">
        <Link to={"terms-and-conditions"}>Terms & Conditions</Link>
        <Link to={"privacy-policy"}>Privacy Policy</Link>
        <Link to={"contact-us"}>Contact Us</Link>
      </div>
    </div>
  );
}

export default App;
