import React from "react";
import "./style.css";
import Logo from "../assets/icons/logo.png";

const AppLinks = () => {
  // Get the current URL including query parameters
  const currentURL = window.location.href;

  // Parse the query parameters
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = {};

  // Convert URLSearchParams object to a plain object
  for (const [key, value] of urlSearchParams.entries()) {
    queryParams[key] = value;
  }

  return (
    <div class="container">
      <header class="header">
        <img src={Logo} alt="App Logo" id="logo" />
      </header>
      <section class="section">
        <h2>
          To launch the Wiseme app,{" "}
          <a target="_blank" href="{currentURL}" class="link">
            click here
          </a>
          <br />
          <br />
          <h3>
            If the above link doesn't work,{" "}
            <a
              target="_blank"
              href={currentURL.replace("https", "wiseme")}
              class="link"
            >
              click here
            </a>
            .
          </h3>
        </h2>
        <br />
        <h3>
          If you don't have the Wiseme app downloaded, first download it from
          the Google Play Store or App Store.
          <br />
          <a
            href="https://play.google.com/store/apps/details?id=com.itsunicorn.wiseme"
            class="download-link"
          >
            Download Android
          </a>
          <a
            href="https://apps.apple.com/in/app/wiseme/id1669168716"
            class="download-link"
          >
            Download iOS
          </a>
        </h3>
        <br />
        <h3> Start using the app today!</h3>
      </section>
    </div>
  );

  return (
    <div className="container">
      <div className="header">
        <img
          src={Logo}
          onClick={() => window.open("https://wiseme.io", "_self")}
        />
      </div>
      <div className="section">
        <h2>
          To launch the app{" "}
          <a
            target="_blank"
            style={{ textDecoration: "underline" }}
            href={currentURL}
          >
            click here
          </a>{" "}
        </h2>
        <br />
        <br />
        <br />
        {/* <h4>
          If the above link, dosen't work{" "}
          <a
            target="_blank"
            style={{ textDecoration: "underline" }}
            href={currentURL.replace("https", "wiseme")}
          >
            click here
          </a>{" "}
        </h4> */}
        <br />
        <h3>
          If you don't have the app downloaded. First download the app from
          Google Play Store and App Store.
          <br />
          <br />
          <a href="https://play.google.com/store/apps/details?id=com.itsunicorn.wiseme">
            Download Android
          </a>
          <br />
          <a href="https://apps.apple.com/in/app/wiseme/id1669168716">
            Download IOS
          </a>{" "}
        </h3>
      </div>
    </div>
  );
};

export default AppLinks;
