import React from "react";
import HeaderComponent from "../components/header";
import LandingBody from "./landing-body";
import AppFeatures from "./app-features";
import AppScreenShots from "./app-screenshots";
import PricingPlans from "./pricing-plan";
import CustomerReviews from "./customer-reviews";
import AppDownload from "./app-download";
import { Collaboration } from "./collaboration";
import { Footer } from "../components/footer";

const Home = () => {
  return (
    <>
      {/* <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="preloader-circle"></div>
            <div className="preloader-img pere-text">
              <img src="images/logo-logo.png" alt="" />
            </div>
          </div>
        </div>
      </div> */}
      <HeaderComponent />
      <main>
        <LandingBody />
        <AppFeatures />
        <AppScreenShots />
        <PricingPlans />
        {/* <CustomerReviews /> */}
        <AppDownload />
        <Collaboration />
      </main>
      <Footer />
    </>
  );
};

export default Home;
