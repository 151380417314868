import React, { useEffect } from "react";
import Logo from "../assets/icons/logo.png";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="header">
        <img src={Logo} />
      </div>
      <div className="section">
        <h1>Contact Us</h1>
        <p>
          To contact us, please send a message to the following email address
          with your inquiry:
          <br />
          contact@wiseme.io
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
